// data
const mianInfo = {
  tel: "22 400 66 33",
  mail: "hej@pinkvegan.pl",
  address: "Browarna 6 lok. 4a",
  addressGoogle: "https://goo.gl/maps/1j9pTT8y7j6buYm6A",
  instagram: "https://www.instagram.com/pinkveganrestauracja/",
  facebook: "https://www.facebook.com/pinkveganrestauracja",
  open: [
    {
      day: "PN-PT",
      hours: "12:00 - 22:00",
    },
    {
      day: "SB-ND:",
      hours: "11:00 - 22:00",
    },
  ],
};

export default mianInfo;
